import { LOCALES } from '@/i18nConfig';

export const locationsApiUrl = (locale: string): string => {
  switch (locale) {
    case LOCALES.EN_AU:
    case LOCALES.EN_NZ:
      return `${process.env.ATM_LOCATIONS_AU_API_URL}`;
    case LOCALES.EN_HK:
    case LOCALES.ZH_HK:
      return `${process.env.ATM_LOCATIONS_HK_API_URL}`;
    default:
      return `${process.env.ATM_LOCATIONS_API_URL}`;
  }
};
