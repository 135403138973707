import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost' | 'light-outline' | 'error-outline';
  children: ReactNode;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;
const baseStyles = 'flex items-center font-medium justify-center h-11 px-3.5 text-button rounded-button transition duration-150 ease-in-out';
const variantStyles = {
  primary: 'bg-primary-600 hover:bg-primary-700 text-gray-600 disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-default',
  secondary: 'bg-secondary-600 hover:bg-secondary-400 border text-white',
  outline: 'text-gray-600 bg-transparent border border-gray-600 hover:bg-gray-100',
  'error-outline': 'text-error-300 bg-transparent border border-error-300 hover:bg-error-100',
  'light-outline': 'border border-gray-200 bg-transparent hover:bg-gray-100',
  ghost: 'text-gray-600 bg-transparent hover:opacity-50'
};
const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  children,
  className = '',
  type = 'button',
  ...props
}) => {
  const styles = clsx(baseStyles, variantStyles[variant] || '', className);
  return <button className={styles} {...props} {...{
    type
  }} data-sentry-component="Button" data-sentry-source-file="index.tsx">
      {children}
    </button>;
};
export default Button;