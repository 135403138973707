import {
  AU_STATE_TO_SLUG_MAPPING,
  NZ_REGION_TO_SLUG_MAPPING,
  PROVINCE_KEY_TO_SLUG_MAPPING,
} from '@/consts/api';
import { GetStateSlugParams } from '@/types/location';

export default function getStateSlug(location: GetStateSlugParams): string | undefined {
  switch (location.country) {
    case 'Canada':
      return PROVINCE_KEY_TO_SLUG_MAPPING[location.state];
    case 'Australia':
      return AU_STATE_TO_SLUG_MAPPING[location.state];
    case 'New Zealand':
      return NZ_REGION_TO_SLUG_MAPPING[location.state];
    default:
      return PROVINCE_KEY_TO_SLUG_MAPPING[location.state];
  }
}
