import { deg2rad, rad2deg } from '../math';
import { Location } from '../types';
export const stringLatLngToNumber = (lat: string, lng: string): google.maps.LatLngLiteral | undefined => {
  const latNum = parseFloat(lat);
  const lngNum = parseFloat(lng);
  if (Number.isNaN(latNum) || Number.isNaN(lng)) {
    return undefined;
  }
  return {
    lat: latNum,
    lng: lngNum
  };
};
export const getDistanceBetweenPointsInKm = (pointA: google.maps.LatLngLiteral, pointB: google.maps.LatLngLiteral): number => {
  if (pointA.lat === pointB.lat && pointA.lng === pointB.lng) {
    return 0;
  }
  const theta = pointA.lng - pointB.lng;
  const distance = rad2deg(Math.acos(Math.sin(deg2rad(pointA.lat)) * Math.sin(deg2rad(pointB.lat)) + Math.cos(deg2rad(pointA.lat)) * Math.cos(deg2rad(pointB.lat)) * Math.cos(deg2rad(theta))));
  return distance * 60 * 1.1515 * 1.609344;
};
export const extractCoordinates = (atmLocation: Location): google.maps.LatLngLiteral | undefined => {
  const {
    location: {
      latitude,
      longitude
    }
  } = atmLocation;
  return stringLatLngToNumber(latitude, longitude);
};