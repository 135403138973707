import { FullDays, ShortDays } from '@/consts/dates';

import { LocationDetail } from '../types';
import { FormattedGroup, TimeSlot } from './types';
import { CLOSED } from './consts';

const formatTimeSlot = (open: string, close: string): string => {
  if (open === '12:00 AM' && close === '12:00 AM') {
    return '24h';
  }

  return open === CLOSED ? CLOSED : `${open} - ${close}`;
};

const formatGroup = (days: string[], timeSlot: TimeSlot): FormattedGroup => {
  const timeSlotString = formatTimeSlot(timeSlot.open, timeSlot.close);

  if (days.length === 1) {
    return { days: days[0], timeSlot: timeSlotString };
  }

  if (days.length === 7) {
    return { days: 'Mon-Sun', timeSlot: timeSlotString };
  }

  return { days: `${days[0]}-${days[days.length - 1]}`, timeSlot: timeSlotString };
};

const getDailyHours = (locationDetails: LocationDetail): Record<string, TimeSlot> => ({
  Mon: {
    open: locationDetails.mondayOpenHour ?? CLOSED,
    close: locationDetails.mondayCloseHour ?? CLOSED,
  },
  Tue: {
    open: locationDetails.tuesdayOpenHour ?? CLOSED,
    close: locationDetails.tuesdayCloseHour ?? CLOSED,
  },
  Wed: {
    open: locationDetails.wednesdayOpenHour ?? CLOSED,
    close: locationDetails.wednesdayCloseHour ?? CLOSED,
  },
  Thu: {
    open: locationDetails.thursdayOpenHour ?? CLOSED,
    close: locationDetails.thursdayCloseHour ?? CLOSED,
  },
  Fri: {
    open: locationDetails.fridayOpenHour ?? CLOSED,
    close: locationDetails.fridayCloseHour ?? CLOSED,
  },
  Sat: {
    open: locationDetails.saturdayOpenHour ?? CLOSED,
    close: locationDetails.saturdayCloseHour ?? CLOSED,
  },
  Sun: {
    open: locationDetails.sundayOpenHour ?? CLOSED,
    close: locationDetails.sundayCloseHour ?? CLOSED,
  },
});

export const getOpenHours = (locationDetails: LocationDetail): FormattedGroup[] => {
  if (checkIf24HoursAllWeek(locationDetails)) {
    return [{ days: 'Mon-Sun', timeSlot: '24/7' }];
  }

  const hours = getDailyHours(locationDetails);
  const result: FormattedGroup[] = [];
  let currentGroup: string[] = [];
  let lastTimeSlot: TimeSlot = { open: '', close: '' };

  ShortDays.forEach((day) => {
    const { open, close } = hours[day];

    if (open === lastTimeSlot.open && close === lastTimeSlot.close) {
      currentGroup.push(day);
    } else {
      if (currentGroup.length) {
        result.push(formatGroup(currentGroup, lastTimeSlot));
      }

      currentGroup = [day];
      lastTimeSlot = { open, close };
    }
  });

  if (currentGroup.length) {
    result.push(formatGroup(currentGroup, lastTimeSlot));
  }

  return result;
};

export const parseTime = (time: string) => {
  const [hours] = time.match(/\d+/g)?.map(Number) || [0, 0];

  return time.includes('PM') && hours !== 12 ? hours + 12 : hours;
};

export const checkIf24HoursAllWeek = (locationDetails: LocationDetail) => {
  return FullDays.every((day) => {
    const openHour = locationDetails[`${day}OpenHour`];
    const closeHour = locationDetails[`${day}CloseHour`];

    return (
      (openHour === '12:00 PM' && closeHour === '12:00 PM') ||
      (openHour === '12:00 AM' && closeHour === '12:00 AM') ||
      (openHour === '12:00 AM' && closeHour === '12:00 PM') ||
      (openHour === '12:00 PM' && closeHour === '12:00 AM')
    );
  });
};

export const checkIfOpenNow = (locationDetails: LocationDetail) => {
  const currentDay = FullDays[new Date().getDay()];
  const currentTime = new Date().getHours();

  if (!locationDetails[`${currentDay}OpenHour`] || !locationDetails[`${currentDay}CloseHour`]) {
    return false;
  }

  const openHour = parseTime(locationDetails[`${currentDay}OpenHour`] || '');
  const closeHour = parseTime(locationDetails[`${currentDay}CloseHour`] || '');

  return currentTime >= openHour && currentTime < closeHour;
};

export const getOpenHoursString = (locationDetails: LocationDetail) => {
  const is24Hours = checkIf24HoursAllWeek(locationDetails);

  if (is24Hours) {
    return '24/7';
  }

  const openHours = FullDays.map((day) => {
    const openHour = locationDetails[`${day}OpenHour`];
    const closeHour = locationDetails[`${day}CloseHour`];

    return `${day}: ${openHour} - ${closeHour}`;
  });

  return openHours.join(', ');
};
