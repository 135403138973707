const processString = (str: string, replacements: [string, string][]) => {
  let result = str.trim().toLowerCase();

  replacements.forEach(([searchValue, replaceValue]) => {
    result = result.split(searchValue).join(replaceValue);
  });

  return encodeURIComponent(result);
};

export const getNameSlug = (name: string) =>
  processString(name, [
    ["'", '_'],
    ['/', '_'],
    [' ', '_'],
    [':', ''],
    ['&', 'and'],
  ]);

export const getStreetSlug = (street: string) =>
  processString(street, [
    [' ', '-'],
    [':', ''],
    ['/', '_'],
    ['&', 'and'],
  ]);

export const getCitySlug = (city: string) =>
  processString(city, [
    [' ', '-'],
    ['&', 'and'],
  ]);

export const getAreaSlug = (area?: string) => (area ? processString(area, [[' ', '-']]) : '');
export const getDistrictSlug = (district?: string) =>
  district ? processString(district, [[' ', '-']]) : '';
