import { stringify } from 'querystring';
import { CoinsDocumentDataCoinsItem, Simplify } from '@/prismicio-types';
import { GroupField } from '@prismicio/client';
import sortBy from 'lodash/sortBy';
import { createClient } from '@/prismicio';
import * as prismic from '@prismicio/client';

import { LOCALES } from '@/i18nConfig';
import { ROUTES } from '@/routes';
import { CryptoResponse } from '@/components/molecules/CryptoCarousel/types';
import { MAP_PIN_URL } from '@/consts/googleMaps';
import { Lang } from '@/types/locales';
import { locationsApiUrl } from '@/app/api/locations/consts';
import { COUNTRY_TO_LOCALE_MAP } from '@/consts/locales';
import { ContentRelationshipCoinExtrasFieldExtended } from '@/prismicCustomTypes';

import { checkIf24HoursAllWeek } from './dates';
import {
  BrowserLocation,
  DaysOfWeek,
  MapFiltersType,
  LocationDetail,
  LocationByIp,
  Location,
  MapStyles,
} from './types';
import { extractCoordinates, getDistanceBetweenPointsInKm } from './location';

export const getDefaultLocation = (locale: string) => {
  const torontoCoords = { latitude: 43.6532, longitude: -79.3832 };

  switch (locale) {
    case LOCALES.EN_CA:
      return torontoCoords;
    case LOCALES.EN_AU:
      // Sydney
      return { latitude: -33.8688, longitude: 151.2093 };
    case LOCALES.EN_NZ:
      // Auckland
      return { latitude: -36.8509, longitude: 174.7645 };
    case LOCALES.EN_HK:
      // Hong Kong
      return { latitude: 22.3193, longitude: 114.1694 };
    default:
      return torontoCoords;
  }
};

export const formatNumber = (
  number: number,
  {
    locale,
    currency = 'CAD',
    defaultFractionDigits = 2,
  }: {
    locale: string;
    currency?: string;
    defaultFractionDigits?: number;
  },
) => {
  let maximumFractionDigits = defaultFractionDigits;
  const decimalPart = number.toString().split('.')?.[1];

  // If there is a decimal part, find the first non-zero digit in the decimal part
  if (decimalPart) {
    const firstNonZeroIndex = decimalPart.search(/[1-9]/);

    if (firstNonZeroIndex !== -1) {
      // Determine the number of decimal places needed to express the value
      maximumFractionDigits = Math.max(defaultFractionDigits, firstNonZeroIndex + 1);
    }
  }

  if (locale === LOCALES.EN_HK) {
    locale = 'en-CA';
    currency = 'CAD';
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits,
  }).format(number);
};

type Props = {
  locale: string;
  coins: GroupField<Simplify<CoinsDocumentDataCoinsItem>>;
  filter?: string;
  location?: {
    result: Location | null;
    error?: string;
  };
};

const COIN_NAME_TO_COINCAP_API_COIN_NAME_MAP: Record<string, string> = {
  ripple: 'xrp',
};

export const getCryptos = async ({
  location,
  coins,
  filter,
  locale,
}: Props): Promise<CryptoResponse> => {
  if (location?.error) {
    return {
      error: location?.error,
    };
  }

  const enabledCoins = coins.filter((item) => item.enabled);

  if (!enabledCoins?.length) {
    return {
      error: 'No coins available',
    };
  }

  const data = location?.result;

  if (!data) {
    return {
      error: `Location with this id doesn't exist`,
    };
  }

  const formatter = (value: number) =>
    formatNumber(value, {
      locale,
      currency: data.currencyCode,
    });

  const limitFormatter = (value: number) =>
    formatNumber(value, {
      locale,
      currency: data.currencyCode,
      defaultFractionDigits: 0,
    });

  const result = await Promise.all(
    enabledCoins.map(async (coin) => {
      const coinTickerLowerCase = coin.ticker?.toLocaleLowerCase() || '';

      const isBuyAvailable = data.cryptocurrencyFeatures[coinTickerLowerCase]?.buyAvailable;
      const isSellAvailable = data.cryptocurrencyFeatures[coinTickerLowerCase]?.sellAvailable;

      const buyValue = isBuyAvailable
        ? formatter(data.prices[`${coinTickerLowerCase}BuyPrice`])
        : '-';

      const sellValue = isSellAvailable
        ? formatter(data.prices[`${coinTickerLowerCase}SellPrice`])
        : '-';

      const buyFeeFlat = isBuyAvailable
        ? formatter(data.prices[`${coinTickerLowerCase}BuyFeeFlat`])
        : '-';
      const sellFeeFlat = isSellAvailable
        ? formatter(data.prices[`${coinTickerLowerCase}SellFeeFlat`])
        : '-';

      let change;

      try {
        const coinName = coin.name?.toLowerCase().replace(/\s/g, '-') || '';

        const res = await fetch(
          ROUTES.APICryptoChanges(COIN_NAME_TO_COINCAP_API_COIN_NAME_MAP[coinName] || coinName),
        );

        change = await res.json();
      } catch (e) {
        console.log(e);
      }

      return {
        change: +change?.data?.changePercent24Hr || 0.0,
        ticker: coin.ticker,
        buyLink: coin.buy_link,
        sellLink: coin.sell_link,
        currencyCode: data.currencyCode,
        buyValue,
        buyFeeFlat,
        coin_extras: (
          coin.coin_extras as ContentRelationshipCoinExtrasFieldExtended
        ).data?.coin_limits.map((item) => ({
          ...item,
          daily_limit: limitFormatter(item.daily_limit as number),
          min_transaction_limit: limitFormatter(item.min_transaction_limit as number),
          max_transaction_limit: limitFormatter(item.max_transaction_limit as number),
        })),
        sellValue,
        sellFeeFlat,
        label: coin.name,
        icon: coin.icon,
      };
    }),
  );

  if (filter === 'sellOnly') {
    return {
      result: result?.filter((row) => row.sellValue !== '-'),
    };
  }

  return {
    result: result,
  };
};

export const tryGetBrowserLocation = (): Promise<BrowserLocation | undefined> =>
  new Promise<BrowserLocation | undefined>((resolve) => {
    window.navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        resolve({
          latitude,
          longitude,
        });
      },
      () => {
        resolve(undefined);
      },
    );
  });

export const getLocationByIpAddress = async (): Promise<LocationByIp | undefined> => {
  try {
    const data = await makeRequest<{ latitude: number | null; longitude: number | null }>(
      ROUTES.APIUserLocation,
    );
    const { latitude, longitude } = data;

    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
      return undefined;
    }

    return {
      latitude,
      longitude,
    };
  } catch (_) {
    return undefined;
  }
};

export class FetchError extends Error {
  response: any;

  constructor(message: string, response: any) {
    super(message);
    this.name = 'FetchError';
    this.response = response;
  }
}

export const makeRequest = async <T>(url: string, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, init);
  const json = (await response.json()) as T;

  if (!response.ok) {
    const error = new FetchError('Request failed', json);

    throw error;
  }

  return json;
};

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const EarthRadius = 6371; // km
  const latitudeDiff = toRad(lat2 - lat1);
  const longitudeDiff = toRad(lon2 - lon1);
  const lat1toRad = toRad(lat1);
  const lat2toRad = toRad(lat2);

  const a =
    Math.sin(latitudeDiff / 2) * Math.sin(latitudeDiff / 2) +
    Math.sin(longitudeDiff / 2) *
      Math.sin(longitudeDiff / 2) *
      Math.cos(lat1toRad) *
      Math.cos(lat2toRad);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = EarthRadius * c;
  return d;
};

// Converts numeric degrees to radians
const toRad = (value: number) => {
  return (value * Math.PI) / 180;
};

export const findNearestATMs = (center: BrowserLocation, locations: Location[], number = 3) => {
  const result = locations
    .map((item) => {
      const distance = calcCrow(
        center.latitude,
        center.longitude,
        +item.location.latitude,
        +item.location.longitude,
      );

      return {
        ...item,
        distance,
      };
    })
    .slice(0, number);

  result.sort((a, b) => {
    return a.distance - b.distance;
  });

  locations.slice(number).forEach((item) => {
    const distance = calcCrow(
      center.latitude,
      center.longitude,
      +item.location.latitude,
      +item.location.longitude,
    );
    for (let i = 0; i < number; i++) {
      if (result[i].distance > distance) {
        result.splice(i, 0, {
          ...item,
          distance,
        });
        result.pop();
        return;
      }
    }
  });
  return result;
};

let mappedStyles: string = '';

export const mapStylesToString = (styles: MapStyles) => {
  if (!styles) {
    return '';
  }

  if (mappedStyles) {
    return mappedStyles;
  }

  const _mappedStyles = styles
    .map((style) => {
      const featureType = style.featureType ? `feature:${style.featureType}` : 'feature:all';
      const elementType = style.elementType ? `element:${style.elementType}` : 'element:all';
      const stylers = style.stylers
        .map((styler) => {
          return Object.entries(styler)
            .map(([key, value]) => {
              if (key === 'color') {
                value = value.replace('#', '0x'); // Zamiana # na 0x
              }
              return `${key}:${value}`;
            })
            .join('|');
        })
        .join('|');
      return `style=${featureType}|${elementType}|${stylers}`; // Dodanie 'style=' na początku
    })
    .join('&');

  mappedStyles = _mappedStyles;

  return _mappedStyles;
};

export const makeGoogleMapsStaticMapUrl = (
  latitude: string,
  longitude: string,
  {
    zoom = 16,
    size = '325x250',
    markerColor = '0xff0000',
    markerSize = 'mid',
    markerLabel = 'A',
    icon = MAP_PIN_URL,
  }: {
    zoom?: number;
    size?: string;
    markerColor?: string;
    markerSize?: string;
    markerLabel?: string;
    icon?: string;
  } = {},
): string => {
  const styles = mapStylesToString(mapStyles);

  const url = `https://maps.googleapis.com/maps/api/staticmap?${stringify({
    center: `${latitude},${longitude}`,
    map_id: process.env.GOOGLE_MAP_ID,
    size,
    key: process.env.GOOGLE_MAPS_API_KEY,
    maptype: 'roadmap',
    format: 'png',
    visual_refresh: true,
    zoom,
    markers: [
      ['icon', icon],
      ['size', markerSize],
      ['color', markerColor],
      ['label', markerLabel],
    ]
      .reduce((acc, [key, value]) => {
        if (!value) {
          return acc;
        }

        const pair = `${key}:${value}`;

        if (!acc) {
          return pair;
        }

        return `${acc}|${pair}`;
      }, '')
      .concat(`|${[latitude, longitude].join(',')}`),
  })}&${styles}`;

  return url;
};

const parseTime12to24 = (timeStr: string) => {
  const timeParts = timeStr.match(/(\d+):(\d+)\s*(AM|PM)/);
  if (!timeParts) {
    return null;
  }

  let hour = parseInt(timeParts[1], 10);
  const minute = parseInt(timeParts[2], 10);
  const am_pm = timeParts[3];

  if (am_pm === 'AM' && hour === 12) {
    hour = 0;
  } else if (am_pm === 'PM' && hour !== 12) {
    hour += 12;
  }

  return { hour, minute };
};

// Function to check if open now based on provided open and close times
const isOpenNow = (
  openHour: string | null,
  closeHour: string | null,
  openDate: Date,
  closeDate: Date,
): boolean => {
  if (!openHour || !closeHour) {
    return false;
  }

  const currentTime = new Date();
  const openTimeObj = parseTime12to24(openHour);
  const closeTimeObj = parseTime12to24(closeHour);

  if (!openTimeObj || !closeTimeObj) {
    return false;
  }

  openDate.setHours(openTimeObj.hour, openTimeObj.minute, 0, 0);
  closeDate.setHours(closeTimeObj.hour, closeTimeObj.minute, 0, 0);

  // Adjust closeDate if closeHour is earlier than openHour, indicating it closes the next day
  if (openDate.getTime() > closeDate.getTime()) {
    closeDate.setDate(closeDate.getDate() + 1);
  }

  return currentTime >= openDate && currentTime <= closeDate;
};

export const parseLocationDetails = (location: LocationDetail) => {
  const currentDate = new Date();
  const today = currentDate
    .toLocaleDateString('en', { weekday: 'long' })
    .toLocaleLowerCase() as DaysOfWeek;

  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(currentDate.getDate() - 1);
  const yesterday = yesterdayDate
    .toLocaleDateString('en', { weekday: 'long' })
    .toLocaleLowerCase() as DaysOfWeek;

  const openHourToday = location[`${today}OpenHour`];
  const closeHourToday = location[`${today}CloseHour`];
  const openHourYesterday = location[`${yesterday}OpenHour`];
  const closeHourYesterday = location[`${yesterday}CloseHour`];

  const is24HoursAllWeek = checkIf24HoursAllWeek(location);

  if (is24HoursAllWeek) {
    return {
      openNow: true,
      openTime: '24/7',
      closeHour: '24/7',
    };
  }

  const is24HoursToday = openHourToday === '12:00 AM' && closeHourToday === '12:00 AM';

  if (is24HoursToday) {
    return {
      openNow: true,
      openTime: '24h',
      closeHour: '24h',
    };
  }

  let openNow = false;
  let openTime = openHourToday;
  let closeHour = closeHourToday;

  // First, check if open now based on today's schedule
  const openDateToday = new Date(currentDate);
  const closeDateToday = new Date(currentDate);

  if (!openHourToday || !closeHourToday) {
    return {
      openNow: false,
      openTime,
      closeHour,
    };
  }

  if (isOpenNow(openHourToday, closeHourToday, openDateToday, closeDateToday)) {
    openNow = true;
  } else {
    // If not open now based on today's schedule, check yesterday's
    const openDateYesterday = new Date(yesterdayDate);
    const closeDateYesterday = new Date(yesterdayDate);

    if (isOpenNow(openHourYesterday, closeHourYesterday, openDateYesterday, closeDateYesterday)) {
      openNow = true;
      openTime = openHourYesterday || '';
      closeHour = closeHourYesterday || '';
    } else {
      openNow = false;
    }
  }

  return {
    openNow,
    openTime,
    closeHour,
  };
};

export const findFilteredLocations = (
  locations: Location[],
  filters: MapFiltersType,
  lang: Lang,
  map?: google.maps.Map,
) => {
  const filterByServiceType = (location: Location) => {
    if (filters.serviceType === 'buy-and-sell') return location.isTwoWay;

    return true;
  };

  const bounds = map?.getBounds();

  const filterByMap = (item: Location) =>
    bounds?.contains({
      lat: +item.location.latitude,
      lng: +item.location.longitude,
    });

  const filterByOpenNow = (location: Location) => {
    const { openNow } = parseLocationDetails(location.location);

    return filters.openNow ? openNow : true;
  };

  const filteredLocations = locations.filter(
    (location) =>
      filterByServiceType(location) && filterByOpenNow(location) && filterByMap(location),
  );

  const mapCenter = map?.getCenter();

  const sortedLocations = sortBy(filteredLocations, [
    (location) => {
      const locationCoordinates = extractCoordinates(location);

      if (!locationCoordinates || !mapCenter) {
        return Number.MAX_SAFE_INTEGER;
      }

      return getDistanceBetweenPointsInKm(locationCoordinates, {
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
      });
    },
  ]);

  return sortedLocations;
};

export const mapStyles: MapStyles = [
  { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
  { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dadada' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c9c9c9' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#262945' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
];
export const nameToSnake = (name: string) => {
  return name.toLowerCase().replace(/[^A-Z0-9]+/gi, '_');
};

export const nameToKebab = (name: string) => {
  return name.toLowerCase().replace(/[^A-Z0-9]+/gi, '-');
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const capitalizeSlug = (sentence: string) => {
  const words = sentence.split('-');

  return words.map((word) => capitalize(word)).join(' ');
};

export const getPageData = async (...params: string[]) => {
  const client = createClient();
  const page = await client
    .getByType('page', {
      filters: [prismic.filter.at('my.page.slug', `/bitcoin-atm/${params.join('/')}`)],
      fetchOptions: {
        cache: 'no-store',
      },
    })
    .catch(() => null);
  return page?.results[0];
};

export const getGlobalLocationData = async (
  option: 'cities' | 'states' | 'locations' | 'areas' | 'districts',
  locale: string,
) => {
  const client = createClient();
  const data = await client
    .getSingle(`global_${option}`, {
      fetchOptions: {
        cache: 'no-store',
      },
      lang: locale,
    })
    .catch(() => null);

  return data;
};

export const getLocationsData = async <T extends Location>(
  locale: string,
): Promise<T[] | undefined> => {
  const res = await fetch(locationsApiUrl(locale), {
    cache: 'no-store',
  });

  if (!res.ok) {
    return undefined;
  }

  const data: T[] = await res.json();

  const mappedData = data.map((item) => ({
    ...item,
    googleMapUrl: item.description?.split(';')[1],
    fullAddress: ROUTES.fullAddress(item.location),
  }));

  switch (locale) {
    // AU and NZ use the same endpoint, so we need to filter them
    case LOCALES.EN_AU:
    case LOCALES.EN_NZ:
      return mappedData.filter((item) => {
        const countryLocale = COUNTRY_TO_LOCALE_MAP[item.location.country];

        return countryLocale === locale;
      });
    default:
      return mappedData;
  }
};

// TODO: Add Hong Kong
export const getPhoneNumberByLocale = (locale: string | undefined): string => {
  switch (locale) {
    case LOCALES.EN_AU:
      return '1800-953-282';
    case LOCALES.EN_NZ:
      return '(0800) 452 242';
    case LOCALES.EN_HK:
      return '+852 800 938 282';
    default:
      return '+1 (877) 412-2646';
  }
};
